html,
body {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: inherit;
}

*:focus:not(:focus-visible) {
  outline: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
